import {createApp, reactive} from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './store'
// 导入插件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 导入axios，设置默认baseURL
import axios from 'axios'
// axios.defaults.baseURL = 'https://nanxia.love/pypi/'
// axios.defaults.baseURL = 'http://localhost:7856'
axios.defaults.baseURL = 'http://116.62.106.13/pypi/'

// 导入字体
import '@/assets/css/text/text.css'



const app = createApp(App)
app.use(ElementPlus)
app.use(pinia)
app.use(router)

// 配置vue启用axios
app.config.globalProperties.$axios = axios
// 挂载
app.mount('#app')